import Swiper, { Autoplay, EffectFade, Lazy } from "swiper";
/**
 * @param swiperInit
 */
export default function swiperInit() {
	new Swiper(".canhcam-section-banner .swiper", {
		slidesPerView: 1,
		lazy: true,
		spaceBetween: 0,
		rewind: true,
		speed: 1000,
		effect: "fade",
		modules: [EffectFade, Autoplay, Lazy],
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
	});
	new Swiper(".section-home-slide-logo .swiper", {
		slidesPerView: 4,
		spaceBetween: 0,
		loop: true,
		speed: 2000,
		modules: [Autoplay],
		autoplay: {
			delay: 0,
			pauseOnMouseEnter: true, // added
			disableOnInteraction: false,
		},
		breakpoints: {
			768: {
				slidesPerView: 5,
			},
			1024: {
				slidesPerView: 8,
			},
			1200: {
				slidesPerView: 10,
			},
		},
	});
	new Swiper(".section-home-project-mobile .swiper", {
		slidesPerView: 1.5,
		spaceBetween: 20,
		breakpoints: {
			768: {
				slidesPerView: 3.5,
			},
			1024: {
				slidesPerView: 3.5,
			},
		},
	});
	if (!window.matchMedia("(min-width: 1200px)").matches) {
		new Swiper(".section-home-services-canhcam .swiper", {
			slidesPerView: 1.1,
			spaceBetween: 1,
			speed: 500,
			enabled: true,
			observeParents: true,
			observer: true,
			breakpoints: {
				768: {
					slidesPerView: 2.2,
				},
				1024: {
					slidesPerView: 2.5,
				},
			},
		});
	}
	new Swiper(".section-home-latest-press .swiper", {
		slidesPerView: 1.1,
		spaceBetween: 0,
		loop: true,
		speed: 500,
		enabled: true,
		breakpoints: {
			768: {
				slidesPerView: 2,
			},
			1024: {
				slidesPerView: 3,
			},
		},
	});
}
