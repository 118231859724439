/*==================== Home ====================*/

import { scrollTo } from './helper';

/**
 * @param home
 */
export const home = {
	video: function () {
		const $container = $('.section-home-story-canhcam');
		const data_video = $container.find('.box-video').data('url-iframe');
		const html_iframe = `<iframe width="560" height="315" src="${data_video}?rel=0&amp;autoplay=1&amp;vq=hd1080" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`;
		$container.find('.btn-play-video').on('click', function () {
			const heightHeader = $('.header-1').outerHeight();
			const offSet =
				$('.section-home-story-canhcam .wrap-story-box').offset().top -
				heightHeader;
			if (!window.matchMedia('(max-width: 767.96px)').matches) {
				scrollTo(offSet, () => {
					// $('.header-1').addClass('slideUp');
					// $('.header-2').removeClass('active');
				});
			}
			$(this).closest('.section-home-story-canhcam').addClass('isPlayingVideo');
			$container.find('.box-video').html(html_iframe);
		});
	},
	init: function () {
		home.video();
	},
};
